<template>
  <span>
    <el-cascader
      :size="size"
      ref="elCascader"
      v-model="val"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
      :filterable="filterable"
      :props="cascaderProps"
      @change="optionChange"
    >
    </el-cascader>
  </span>
</template>

<script>
import { initTreeData } from '@/util/common'
import { listSysDictData } from '@/api/dict'
export default {
  name: 'VatDictionary',
  components: {},
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      //是否显示清空按钮
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      //是否可以选择分支节点
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    valKey: {
      type: String,
      default: 'vatType',
    },
    expandTrigger: {
      type: String,
      default: 'hover',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'vatName',
    },
    valType: {
      type: String,
      default: '',
    },
    code: {
      type: String,
    },
    value: {},
    size: {
      type: String,
      default: '',
    },
    Options: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      that: this,
      val: null,
      options: this.Options || [],
      cascaderProps: {
        multiple: this.multiple,
        expandTrigger: this.expandTrigger,
        checkStrictly: this.checkStrictly,
        value: this.valKey,
        label: this.label,
        emitPath: false,
      },
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  watch: {
    code: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          if (this.valType === 'Data') {
            this.initData()
          } else if (this.valType === 'TaxRate') {
            this.initTaxRate()
          }
        } else {
          this.options = []
          this.$emit('change', '')
        }
      },
    },
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (this.multiple) {
          if (newVal) {
            this.val = newVal.split(',')
          }
        } else {
          this.val = newVal
        }
        //this.val = newVal;
      },
    },
  },
  mounted() {},
  methods: {
    initTaxRate() {
      this.$api.vat
        .getVatRateList({ vatData: this.code, pageNow: 1, pageSize: 999 })
        .then(res => {
          let arr = []
          if (res.data?.records) {
            res.data.records.forEach(v => {
              arr.push({
                ...v,
                vatType: v.vatRate,
                vatName: v.vatRate,
              })
            })
          }
          this.options = arr
          this.$emit('optionsChange', this.options)
        })
        .catch(err => {
          console.log(err)
        })
    },
    initData() {
      this.$api.vat
        .getVatDataList({ vatType: this.code, pageNow: 1, pageSize: 999 })
        .then(res => {
          let arr = []
          if (res.data?.records) {
            res.data.records.forEach(v => {
              arr.push({
                ...v,
                vatType: v.vatData,
              })
            })
          }
          this.options = arr
          this.$emit('optionsChange', this.options)
        })
        .catch(err => {
          console.log(err)
        })
    },
    optionChange(v) {
      var value = null
      if (v) {
        if (this.multiple) {
          value = v.join()
        } else {
          value = v
        }
      }
      this.$emit('dictionaryChange', this.$refs.elCascader.getCheckedNodes())
      this.$emit('change', value)
    },
  },
}
</script>
